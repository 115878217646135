/*
 * @Author: chengyuzhang
 * @Date: 2020-04-26 14:33:20
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-04-26 17:13:17
 */

export default {
    namespaced: true,

    state: () => ({
        messageList: [],
        messageContent: {}
    }),

    mutations: {
        setMessageList: (state, messageList) => {
            state.messageList = messageList;
        },

        setMessageContent: (state, messageContent) => {
            state.messageContent = messageContent;
        }
    }
};
