<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-04-21 10:53:13.962 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <ec-message-list
        class="message"
        :no-message-text="$t('webview.message.noMessage')"
        :read-all-text="$t('webview.message.readAllText')"
        :init-message="messageList"
    />
</template>

<script type="text/babel">
import EcMessageList from 'easycash/common/component/ec-message-list';
import {message} from 'easycash/common/mixin/message-manage';

const {asyncData, ...rest} = message;

export default {
    name: 'Message',

    title() {
        return this.$t('webview.message.title');
    },

    components: {
        EcMessageList
    },

    mixins: [rest],

    asyncData
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
::v-deep.message-list {
    min-height: 100vh;
    line-height: 1.3;

    .item {
        padding: 5px 25px;

        .mint-cell-wrapper {
            .mint-cell-value {
                line-height: 1.3;

                .msg-date {
                    order: 2;
                    margin-top: 12px;
                }
            }
        }

        &.read {
            .mint-cell-value {
                color: #333 !important;

                .msg-title {
                    font-weight: 400;
                }
            }
        }
    }

    .btn-read-all {
        border-color: #ff734b;
        color: #ff734b;
    }

    .delete-wrapper {
        g {
            fill: #d84b35;
        }
    }
}
</style>
