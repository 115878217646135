var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ec-message-list", {
    staticClass: "message",
    attrs: {
      "no-message-text": _vm.$t("webview.message.noMessage"),
      "read-all-text": _vm.$t("webview.message.readAllText"),
      "init-message": _vm.messageList,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }