/*
 * @Author: chengyuzhang
 * @Date: 2020-04-26 13:52:04
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-04-26 19:22:11
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from 'easycash/common/constant/config';

const api = {
    getHost: getEurekaHost,

    url: '/api/messages/:id',

    getMessageList: {
        url: '/api/messages',
        method: 'get'
    },

    readAllMessage: {
        url: '/api/messages/readAll',
        method: 'post'
    }
};

export default Resource.create(api);
