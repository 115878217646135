/*
 * @Author: chengyuzhang
 * @Date: 2020-04-26 14:40:30
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2020-07-02 15:15:07
 */

import _ from 'underscore';

import Message from 'easycash/common/resource/message';
import messageModule from 'easycash/common/store/module/message';

export const message = {
    async asyncData({store, cookie}) {
        try {
            store.registerModule('message', messageModule);
            const {data: {body: {responseList}}} = await Message.getMessageList({
                params: {
                    pageSize: 10
                },
                headers: {
                    cookie
                }
            });
            store.commit('message/setMessageList', responseList);
        } catch (err) {
            if (_.propertyOf(err)('data.status.code'.split('.')) === 4001) {
                YqgReporter.warn(err);

                return;
            }

            YqgReporter.fatalException(err);
        }
    },

    data() {
        const {messageList} = this.$store.state.message || [];

        return {
            messageList
        };
    }
};

export const messageDetail = {
    async asyncData({store, route, cookie}) {
        try {
            store.registerModule('message', messageModule);
            const {data: {status: {code, detail}, body}} = await Message.get({
                id: route.params.id,
                headers: {cookie}
            });
            if (code === 0) {
                store.commit('message/setMessageContent', body || {});
            } else {
                store.commit('message/setMessageContent', {content: detail});
            }
        } catch (err) {
            YqgReporter.fatalException(err);
        }
    },

    data() {
        const {messageContent} = this.$store.state.message || [];

        return {
            messageContent
        };
    }
};
